nav {
    background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(8,8,8,1) 100%);
  }

ul {
    margin: 5px;
}

li, .list-item {
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-left: 20px;
    margin-right: 20px;
}

nav ul, .unorder {
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

#resume-link {
  display: flex;
  list-style: none;
  text-decoration: none;

  justify-content: space-around;
  align-items: center;
}

.resume-icon {
  color: #999999;
  width: 12px; /* Limit our width to this */
}
.resume-icon:hover {
  color: #DDDDDD;
}
.resume-icon:active {
  color: #666666;
}

.gradient-text-highlight:hover {
  background: -webkit-linear-gradient(#eee, #999);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-text-highlight:active {
  background: -webkit-linear-gradient(#eee, #111);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}