
#timeline-item-div {
    justify-items: center;
    text-align: center;
}

#company-image {
    max-height: 250px;
    max-width: 350px;
    border-radius: 10px;
    /* border: 1px solid gray; */
    padding: 10px;
}

#vertical-line {
    border-left: 1px solid black;
    height: 100px;
    max-width: 1px;

    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 10px;
}

.sub-details {
    font-size: 18px;
    color: #555555;
}