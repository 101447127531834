/* myComponent.css */

.myBubbleUI {
	width: 100%;
	height: 500px;
	/* border-radius: 50px; */
    /* background: -webkit-linear-gradient(#eee, #999); */
    /* background: linear-gradient(90deg, rgba(200,200,200,1) 20%, rgba(230,230,230,1) 100%); */
    border-top: 3px solid #555555;
}

.child {
	width: 100%;
	height: 100%;
	border-radius: 50%;
    background-color: red; /* error color? */
    text-align: center; /* center content horizontally */
    padding: 30% 0; /* center content vertically with magic number*/
}

.child h3 {
    font-size: 20px;
}

.child h4 {
    font-size: 15px;
}

.language {
    /* background-color: #66c2ff; */
    background: linear-gradient(80deg, #74c69d 20%, #52b788 100%);
    border: 1px solid #2d6a4f;
}
.framework {
    background: linear-gradient(80deg, #00a6fb 20%, #0582ca 100%);
    border: 1px solid #006494;
}
.application {
    background: linear-gradient(80deg, #ff9ebb 20%, #ff7aa2 100%);
    border: 1px solid #e05780;
}
.ci {
    background: linear-gradient(80deg, #faa307 20%, #f48c06 100%);
    border: 1px solid #e85d04;
}