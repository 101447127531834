div #experience-div h1 {
    text-align: center;
}

#experience-div {
    padding: 5vw;
    padding-top: 5vh;
    background: linear-gradient(90deg, rgba(230,230,230,1) 20%, rgba(255,255,255,1) 100%);
}

div #experience-div h1 {
    text-align: center;
}

div #experience-div {
    font-family: "Lucida Console", Monaco, monospace;
}
