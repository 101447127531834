
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;900&display=swap');

@font-face {
  font-family: zenFontFace;
  src: url(/public/Zen_Dots/ZenDots-Regular.ttf);
}

.zen-dot-font {
  font-family: zenFontFace;
  letter-spacing: 0.1em;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

/* TODO read this since I just pasted this oops https://cssgradient.io/blog/css-gradient-text/ */
.gradient-text {
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

body, html {
  /* https://cssgradient.io/*/
  background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(8,8,8,1) 100%);
}

body {
  padding-top: 10vh;
}

#app {
  background: linear-gradient(90deg, rgba(180,180,180,1) 50%, rgba(210,210,210,1) 100%);
}

.white-background {
  background: linear-gradient(90deg, rgba(180,180,180,1) 50%, rgba(210,210,210,1) 100%);
}


p {
  color: white;
  font-family: "Lucida Console", Monaco, monospace;
}

.lucida {
  font-family: "Lucida Console", Monaco, monospace;
}

.black{
  color: #0F0F0F;
}

.white{
  color: #F0F0F0;
}

.card-text{
  font-size: 14px;
}

.card-body {

}