#project-div {
    padding: 5vw;
    background: linear-gradient(90deg, rgba(230,230,230,1) 20%, rgba(255,255,255,1) 100%);
    min-height: 90vh; /* The navbar is 10vh */
}

.raised-card {
    background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(8,8,8,1) 100%);
    border-radius: 20px;
    padding: 30px;
}

div#close-icon {
    text-align: right;
    padding-bottom: 15px;
}

.off-white {
    color: #DDDDDD;
}

/* .raised-card img {
    width: 50%;
    max-width: 800px;
} */

#carousel {
    max-width: 800px;
}

.project-date {
    text-align: center;
    margin: 5px;
}


.repo-link {
    background-color: transparent;
    color: #DDDDDD;
    text-align: center;
    border-radius: 10px;
    border: 1px solid white;
    max-width: 300px;
    padding: 10px;
}

.repo-div {
    display: flex;
    justify-content: center;
}

a .repo-link:hover {
    background-color: #333333;
}