
#profile-photo-div {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 90vh;

    /* Overlay this above the cityscape */
    z-index: 10;
    position: relative;
}

#black-tint-overlay {
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1;
    height: 100vh;
    width: 100vw;
    position: fixed;
}


#profile-photo {
    width: 70%;
    max-width: 300px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
@media (max-width: 600px) {
    #profile-photo-div {
        display: block;
        padding: 20px;
        padding-top: 10vh;
    }
    #small-center {
        display: flex;
        justify-content: center;
    }
    #profile-photo {
        width: 50%;
    }
}

#home-content {
    min-height: 100vh;

    /* https://cssgradient.io/*/
    background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(8,8,8,1) 100%);
}


#name {
    font-size: 80px;
}
@media (max-width: 600px) {
    #name {
        font-size: 50px;
    }
    .detail-text {
        font-size: 20px;
    }
}



#home-content #about-me a {
    text-decoration: none;
}

#profile-text-div {
    margin: 20px;
    text-align: center;
}

#about-me-overlay-div {
    z-Index: 10;
    position: relative;
    background-color: black;
    min-height: 90vh;

    padding-top: 5vw;
    padding-bottom: 5vw;
    padding-left: 10vw;
    padding-right: 10vw;
}

.off-white {
    color: #CCCCCC;
}

.about-me-text {
    color: #CCCCCC;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.8em;
    padding-top: 20px;
}



#icon-div {
    text-align: center;
    /* margin: 50px; */
}

.icon {
    color: white;
}
.icon:hover {
    color: #CCCCCC;
}
.icon:active {
    color: #888888;
}
.home-icon {
    width: 80px; /* Limit our width to this */
    margin: 20px;
    margin-top: 0px;
}