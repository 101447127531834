#center-div {
    justify-content: center;
    align-items: center;
    display: flex;
}

.my-card {
    width: 20rem;
}

#projects-div {
    padding: 20px;
    padding-top: 30px;
    background: linear-gradient(90deg, rgba(230,230,230,1) 20%, rgba(255,255,255,1) 100%);
}


.card {
    background: linear-gradient(90deg, rgba(0,0,0,1) 20%, rgba(8,8,8,1) 100%);
}

.card:hover {
    background: linear-gradient(90deg, rgba(40,40,40,1) 20%, rgba(48,48,48,1) 100%);
}






/* https://stackoverflow.com/questions/29732575/how-to-specify-line-breaks-in-a-multi-line-flexbox-layout*/

.flex-grid, .small-flex-grid {
    display: flex;
    flex-flow: wrap;
  }
  
  /* Split among 12 columns, how many are there?*/
  .flex-col-1 {flex: 0 0 8.3333%}
  .flex-col-2 {flex: 0 0 16.6666%}
  .flex-col-3 {flex: 1 1 25%}
  .flex-col-4 {flex: 1 1 33.3333%}
  .flex-col-5 {flex: 0 0 41.6666%}
  .flex-col-6 {flex: 0 0 50%}
  .flex-col-7 {flex: 0 0 58.3333%}
  .flex-col-8 {flex: 0 0 66.6666%}
  .flex-col-9 {flex: 0 0 75%}
  .flex-col-10 {flex: 0 0 83.3333%}
  .flex-col-11 {flex: 0 0 91.6666%}
  .flex-col-12 {flex: 0 0 100%}
  
  [class*="flex-col-"] {
    margin: 0 0 10px 0;
  
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  
  @media (max-width: 800px) {
    .flex-grid {
      display: block;
    }
  }


  .small-project-card {
    max-width: 32%; /* Jank number, 33.33% with padding */
    padding: 5px;
}
.large-project-card {
    max-width: 49.0%; /* Jank number, 50% with padding */
    padding: 5px;
}


  /* Put two small cards on a row instead of 3 */
  @media (max-width: 800px) {
    .small-project-card {
        flex: 1 1 33.3333%;
        max-width: 49%; /* Jank number, 50% with padding */
    }
    .large-project-card {
        max-width: 100%;
    }
  }

  @media (max-width: 600px) {
    .small-flex-grid {
      display: block;
    }
    .small-project-card {
        max-width: 100%;
    }
  }




/* https://stackoverflow.com/questions/16615403/how-to-draw-a-circle-with-text-in-the-middle */

  .badge {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    border-radius: 10px;
    border: 1px solid grey;
    background-color: #060606;
    padding: 5px;
  }

  .vertical-center {
    display: flex;
    align-items: center;
  }