#skills-div {
    /* padding: 100px; */
    padding-top: 5vh;
    height: 95vh;
    background: linear-gradient(90deg, rgba(230,230,230,1) 20%, rgba(255,255,255,1) 100%);
}

div #skills-div h1 {
    text-align: center;
}

div #skills-div {
    font-family: "Lucida Console", Monaco, monospace;
}


#color-key li {
    font-size: 12px;
    border-radius: 0.375rem;
    border-width: 1px;
}

#color-key {
    justify-content: center;
    padding-bottom: 10px;
}